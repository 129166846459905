import { Navigate, Route, Routes } from "react-router-dom";
import { OnexAppRoutes } from "../../../constants/routes.constants";
import Orders from "../../../pages/Orders/Orders";
import Home from "../../../pages/Home";
import CustomerSingle from "../../../pages/SingleCustomer";
import Balance from "../../../pages/Balance/Index";
import Scan from "../../../pages/Scan";
import ScanSingle from "../../../pages/Scan/Single";
import Consignment from "../../../pages/Consignment";
import Expected from "../../../pages/Expected";
import OrdersCheck from "../../../pages/Orders/Check";
import Delivery from "../../../pages/Delivery/Index";
import Customers from "../../../pages/Customers/Index";
import InArmenia from "../../../pages/InArmenia";
import DownloadExcel from "../../../pages/DownloadExcel.jsx";
import ReceiveOrders from "../../../pages/ReceiveOrders/index.jsx";
import Dashboard from "../../../pages/Dashboard";
import Members from "../../../pages/MembersAndRoles/index.jsx";
import AppVersions from "../../../pages/AppVersions";
import Slides from "../../../pages/Slides/index.jsx";
import Blog from "../../../pages/Blog/index.jsx";
import Reports from "../../../pages/Reports";
import Banners from "../../../pages/Banners";
import Bonus from "../../../pages/Bonus/Index.jsx";
import Shops from "../../../pages/Shops/index.jsx";
import Notifications from "../../../pages/Notifications";
import OrdersFailed from "../../../pages/OrdersFailed/index.jsx";
import Stories from "../../../pages/Story";
import Roles from "../../../pages/MembersAndRoles/Roles";
import ActionsHistory from "../../../pages/ActionsHistory";
import OnlinePayment from "../../../pages/OnlinePayment/Index.jsx";
import PrimeUsers from "../../../pages/PrimeUsers";
import TestUsers from "../../../pages/TestUsers/index.jsx";
import Tariffs from "../../../pages/Tariffs/index.jsx";
import Sidebar from "../Sidebar";
import styled from "styled-components";
import LostOrders from "../../../pages/LostOrders/index.jsx";
import CustomizedPricing from "../../../pages/CustomizedPricing/index.jsx";

const StyledLayout = styled.div`
  .content {
    padding-bottom: 24px;
    width: 100%;
    margin-left: 100px;
    margin-right: 20px;
    @media (max-width: 1400px) {
      width: 90%;
    }
    @media (max-width: 1135px) {
      width: 88%;
    }
    @media (max-width: 991px) {
      width: 85%;
    }
    @media (max-width: 768px) {
      width: calc(100% - 48px);
      margin-left: 24px;
      margin-right: 24px;
    }
  }
`;

export default function AuthenticatedApp() {
  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];
  return (
    <StyledLayout>
      <Sidebar>
        <Routes>
          <Route path={OnexAppRoutes.home()} element={<Home />} />
          {permissions["order"] ? (
            <Route
              path={OnexAppRoutes.orders()}
              element={<Orders permissions={permissions["order"]} />}
            />
          ) : null}
          {permissions["recipient"] ? (
            <Route path={OnexAppRoutes.customers()} element={<Customers />} />
          ) : null}
          {permissions["balance"] ? (
            <Route
              path={OnexAppRoutes.balance()}
              element={<Balance permissions={permissions["balance"]} />}
            />
          ) : null}
          {permissions["bonus"] ? (
            <Route
              path={OnexAppRoutes.bonus()}
              element={<Bonus permissions={permissions["bonus"]} />}
            />
          ) : null}

          <Route
            path={OnexAppRoutes.onlinePayment()}
            element={
              permissions["balance"] ? (
                <OnlinePayment permissions={permissions["balance"]} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route path={OnexAppRoutes.scan()} element={<Scan />} />
          <Route path={OnexAppRoutes.scanSingle()} element={<ScanSingle />} />
          {permissions["parcel"] ? (
            <Route
              path={OnexAppRoutes.consignment()}
              element={<Consignment permissions={permissions["parcel"]} />}
            />
          ) : null}
          {permissions["expected"] ? (
            <Route
              path={OnexAppRoutes.expected()}
              element={<Expected permissions={permissions["expected"]} />}
            />
          ) : null}
          <Route path={OnexAppRoutes.ordersCheck()} element={<OrdersCheck />} />
          <Route
            path={OnexAppRoutes.ordersFailed()}
            element={<OrdersFailed />}
          />
          {permissions["delivery-home"] ? (
            <Route path={OnexAppRoutes.delivery()} element={<Delivery />} />
          ) : null}
          <Route path={OnexAppRoutes.inArmenia()} element={<InArmenia />} />
          <Route path={OnexAppRoutes.dashboard()} element={<Dashboard />} />
          <Route
            path={OnexAppRoutes.downloadExcel()}
            element={<DownloadExcel />}
          />
          <Route
            path={OnexAppRoutes.receiveOrders()}
            element={<ReceiveOrders permissions={permissions["order"]} />}
          />
          <Route
            path={OnexAppRoutes.customerSingle()}
            element={
              <CustomerSingle
                permissions={permissions["recipient"]}
                clientLogin={!!permissions["client-login"]}
              />
            }
          />
          {permissions["role"] ? (
            <Route
              path={OnexAppRoutes.membersAndRoles()}
              element={<Members permissions={permissions["role"]} />}
            />
          ) : null}
          {permissions["slider"] ? (
            <Route
              path={OnexAppRoutes.slides()}
              element={<Slides permissions={permissions["slider"]} />}
            />
          ) : null}
          {permissions["blog"] ? (
            <Route
              path={OnexAppRoutes.blog()}
              element={<Blog permissions={permissions["blog"]} />}
            />
          ) : null}
          {permissions["header-message"] ? (
            <Route
              path={OnexAppRoutes.banners()}
              element={<Banners permissions={permissions["header-message"]} />}
            />
          ) : null}
          {permissions["shop"] ? (
            <Route
              path={OnexAppRoutes.shops()}
              element={<Shops permissions={permissions["shop"]} />}
            />
          ) : null}
          <Route path={OnexAppRoutes.reports()} element={<Reports />} />
          {permissions["customer-notification"] ? (
            <Route
              path={OnexAppRoutes.notifications()}
              element={<Notifications />}
            />
          ) : null}
          {permissions["story"] ? (
            <Route path={OnexAppRoutes.stories()} element={<Stories />} />
          ) : null}

          <Route
            path={OnexAppRoutes.roles()}
            element={<Roles permissions={permissions["role"]} />}
          />
          <Route
            path={OnexAppRoutes.actionsHistory()}
            element={
              permissions["role"] ? <ActionsHistory /> : <Navigate to="/" />
            }
          />
          <Route
            path={OnexAppRoutes.lost_orders()}
            element={
              permissions["lost-order"] ? <LostOrders /> : <Navigate to="/" />
            }
          />
          <Route path={OnexAppRoutes.prime_users()} element={<PrimeUsers />} />
          <Route
            path={OnexAppRoutes.test_users()}
            element={
              permissions["test-user"] ? <TestUsers /> : <Navigate to="/" />
            }
          />
          <Route path={OnexAppRoutes.tariffs()} element={<Tariffs />} />
          <Route
            path={OnexAppRoutes.customized_pricing()}
            element={<CustomizedPricing />}
          />
          <Route
            path={OnexAppRoutes.app_versions()}
            element={
              permissions["api-version"] ? <AppVersions /> : <Navigate to="/" />
            }
          />
        </Routes>
      </Sidebar>
    </StyledLayout>
  );
}
