import styled from "styled-components";
import { message, Pagination, Table } from "antd";
import { Tooltip } from "react-tooltip";
import EyeIcon from "../../components/SvgComponents/EyeIcon";
import Flex from "../../components/general/Flex";
import Typography from "../../components/moleculs/Typography.jsx";
import PrimeIcon from "../../components/SvgComponents/primeIcon";
import EstimateIcon from "../../components/SvgComponents/EstimateIcon";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon";
import Loader from "../../components/moleculs/Loader";
import OnexTooltip from "../../helpers/tooltip.jsx";
import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import { statusTexts } from "../../utils/statusTexts.js";
import OrderStatusIcon from "./OrderStatusIcon";
import DeliveryIcon from "../../components/SvgComponents/DeliveryIcon";
import ReadyIcon from "../../components/SvgComponents/ReadyIcon";
import { DownloadOrdersExcelService } from "../../services/downloadExcel.js";
import AlertCircleIcon from "../../components/SvgComponents/AlertCircleIcon";
import BfmIcon from "../../components/SvgComponents/BfmIcon";
import Tag from "../../components/moleculs/Tag.jsx";
import dayjs from "dayjs";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";
import WhIcon from "../../components/SvgComponents/WhIcon.jsx";
import { useState } from "react";
import ImageModal from "../../components/modals/ImageModal.jsx";
import { DelivoIcon } from "../../components/SvgComponents/DelivoIcon.jsx";
import { HayPostIcon } from "../../components/SvgComponents/HayPostIcon.jsx";

const StyledOrdersTable = styled.div`
  table {
    background: white;
    padding: 16px;
    border-radius: 12px;

    td {
      vertical-align: top !important;
    }
  }

  .show-details {
    background: transparent;
    border: none;
    outline: none;
  }

  table {
    thead {
      display: none;
    }

    tbody {
      tr {
        td:nth-child(1) {
          padding: 16px 0 !important;
        }

        td:last-child {
          padding: 16px 0 !important;
        }
      }
    }

    .product-name {
      padding-right: 5px;
      border-right: 1px solid #e7e9ec;
      margin-top: 10px;
    }

    .product-description {
      margin-top: 10px;
      display: -webkit-box;
      overflow: hidden;
      max-height: 44px;
      text-overflow: ellipsis;
      transition: all 0.2s;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .download-excel {
    margin-top: 24px;
  }

  .not-orders-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 65vh;
    justify-content: center;

    .search-icon {
      background: #e7e9ec;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 31px;
    }
  }

  .ant-table-content {
    max-height: 60vh;
    overflow: auto;
  }
`;

export default function OrdersTable({
  setDetailsStatus,
  id,
  setId,
  orders,
  meta,
  setFilters,
  filters,
  isLoading,
  permissions,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [photoOrder, setPhotoOrder] = useState(null);

  const handleDownloadExcel = () => {
    DownloadOrdersExcelService(filters).then((res) => {
      console.log(res);
    });
  };

  const columns = [
    {
      key: "country",
      dataIndex: "country",
      title: "",
      width: "7%",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "",
      width: "24%",
    },
    {
      key: "tracking",
      dataIndex: "tracking",
      title: "",
      width: "10%",
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "",
      width: "7%",
    },
    {
      key: "price",
      dataIndex: "price",
      title: "",
    },
    {
      key: "date",
      dataIndex: "date",
      title: "",
    },
    {
      key: "status",
      dataIndex: "status",
      title: "",
    },
    {
      key: "pickup_point",
      dataIndex: "pickup_point",
      title: "",
    },
    {
      key: "eye",
      dataIndex: "eye",
      title: "",
      render: (_, record) => (
        <Flex>
          {/* <button */}
          {/*   disabled={true} */}
          {/*   className="show-details" */}
          {/*   onClick={() => { */}
          {/*     console.log(record.id); */}
          {/*     setDetailsStatus(true); */}
          {/*     setId(record.id); */}
          {/*   }} */}
          {/* > */}
          {/*   <LocationMaker /> */}
          {/* </button> */}
          {PermissionFinder(permissions, "order-view") ? (
            <button
              className="show-details"
              onClick={() => {
                setDetailsStatus(true);
                setId(record.id);
              }}
            >
              <EyeIcon />
            </button>
          ) : null}
        </Flex>
      ),
    },
  ];

  let data = null;
  if (orders) {
    data = orders?.data?.map((order, index) => {
      const isRefunded = order?.histories.some(
        (el) => el.key === "refund_date"
      );
      return {
        key: index,
        id: order.id,
        country: (
          <div>
            <Flex alignItems={"center"}>
              <OnexTooltip content={"Warehouse"} direction={"bottom"}>
                <img src={order?.warehouse?.round_flag || ""} alt="" />
              </OnexTooltip>
              <div style={{ paddingLeft: 8 }} id="dispatch">
                <img src={order?.dispatch?.icon || ""} alt="" />
              </div>
            </Flex>
            <Typography
              variant="span"
              text={order?.parcel?.name || ""}
              color="#262626"
              size="14px"
              weight={500}
            />
          </div>
        ),
        name: (
          <Flex>
            <Typography
              text={`${order?.recipient?.first_name || ""} ${
                order?.recipient?.last_name || ""
              } ${order?.recipient?.company_name || ""} ${
                order?.recipient?.user_code
              }`}
              variant="p"
              size="14px"
              weight="500"
              color=" #262626"
            />
            {order?.recipient?.user?.is_prime ? (
              <OnexTooltip content={"Prime"} direction={"bottom"}>
                <div style={{ paddingLeft: 4 }}>
                  <PrimeIcon />
                </div>
              </OnexTooltip>
            ) : null}
          </Flex>
        ),
        tracking: (
          <div>
            <div
              className={"c-pointer"}
              onClick={() => {
                navigator.clipboard.writeText(order?.tracking_code);
                messageApi.success("Copied");
              }}
            >
              <Flex alignItems="start">
                <Flex direction="column">
                  <OnexTooltip content={"Click to copy"} direction={"bottom"}>
                    <Typography
                      variant="p"
                      text={order?.tracking_code}
                      color="#262626"
                      size="14px"
                      weight="400"
                    />
                  </OnexTooltip>
                  <Flex alignItems="center" style={{ marginBottom: "6px" }}>
                    <Typography
                      variant="p"
                      text={order?.logistic_order?.tracking_code}
                      color="#262626"
                      size="14px"
                      weight="400"
                      margin={"0 0 0 5px"}
                    />
                  </Flex>
                </Flex>
                {order?.purchase_type === "buy_for_me" ? (
                  <OnexTooltip content={"Buy for me"} direction={"bottom"}>
                    <BfmIcon />
                  </OnexTooltip>
                ) : null}
              </Flex>
            </div>
            {order?.customer_comment ? (
              <Flex>
                {/* <div className="product-name">/!* <QuestionIcon /> *!/</div> */}
                <div className="product-description">
                  <Typography
                    variant="p"
                    text={order?.customer_comment}
                    color="#5B6D7F"
                    size="14px"
                    weight={400}
                  />
                </div>
              </Flex>
            ) : null}
            {order?.dangerous ? (
              <OnexTooltip direction={"top"} content={"Dangerous"}>
                <AlertCircleIcon margin={"10px 0 0 0"} />
              </OnexTooltip>
            ) : order?.lost ? (
              <Tag
                text={"lost"}
                background={"red"}
                color={"#fff"}
                padding={"0 12px"}
              />
            ) : (
              ""
            )}
            {!!order?.pickup_point?.partner && (
              <Typography
                variant={"p"}
                text={
                  <>
                    {order?.pickup_point?.partner?.name === "Delivo" ? (
                      <DelivoIcon margin={"0 3px -3px 0"} />
                    ) : order?.pickup_point?.partner?.name === "Haypost" ? (
                      <HayPostIcon />
                    ) : (
                      ""
                    )}
                    {/*{order?.delivery_order?.delivery_pickup?.tracking_number ||*/}
                    {/*  ""}*/}
                  </>
                }
                size="14px"
              />
            )}
          </div>
        ),
        weight: (
          <OnexTooltip content={"Weight"} direction={"bottom"}>
            <Typography
              text={`${order?.weight} kg`}
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
            />
          </OnexTooltip>
        ),
        price: (
          <Flex direction={"column"}>
            <Flex>
              <Typography
                variant="p"
                text={`${order?.cost}  ₾`}
                color="#262626"
                weight="400"
                size="14px"
                padding="0 7px 0 0"
              />
              {order?.additional_cost ? (
                <OnexTooltip content={"Add cost"} direction={"bottom"}>
                  <Typography
                    text={order?.additional_cost + " ₾"}
                    variant={"p"}
                    color={"#8E9BA7"}
                    size={"14px"}
                  />
                </OnexTooltip>
              ) : null}
            </Flex>
            {!order?.declaration_price ? (
              <Typography
                variant="p"
                text="Not declared"
                color="#FC4447"
                weight="400"
                size="14px"
                padding="10px 0 0 0"
              />
            ) : (
              <Flex>
                <Typography
                  variant="p"
                  text={`${
                    order?.declaration_price
                      ? order?.declaration_price +
                        " " +
                        order?.declaration_currency
                      : ""
                  } `}
                  color="#5B6D7F"
                  weight="400"
                  size="14px"
                  padding="10px 7px 0 0"
                />
              </Flex>
            )}
          </Flex>
        ),
        status: (
          <div>
            <Flex alignItems="center">
              <OrderStatusIcon status={order?.status} />
              <Flex>
                <Typography
                  variant="p"
                  text={statusTexts[order?.status]}
                  color="#5B6D7F"
                  size="14px"
                  weight="400"
                  margin="0 0 0 5px"
                />
                <OnexTooltip
                  content={order?.histories[order?.histories?.length - 1]?.date}
                  direction={"bottom"}
                >
                  <Typography
                    variant="p"
                    text={
                      order?.histories?.length > 0
                        ? dayjs(
                            order?.histories[order?.histories?.length - 1]?.date
                          ).format("DD.MM.YYYY")
                        : ""
                    }
                    color="#262626"
                    size="14px"
                    weight="400"
                    margin="0 0 0 5px"
                  />
                </OnexTooltip>
              </Flex>
            </Flex>
            <Flex alignItems="center" style={{ paddingTop: 10 }}>
              <Flex>
                {isRefunded ? null : order?.status === "in_georgia" ? (
                  <Flex>
                    {order?.ready_for_pickup ? (
                      <>
                        <ReadyIcon />
                        <Typography
                          variant="p"
                          text={`Ready`}
                          color="#5dba2f"
                          size="14px"
                          weight="400"
                          margin="0 0 0 5px"
                        />
                      </>
                    ) : order?.custom?.custom_group?.custom_status?.status ===
                      "double_check" ? (
                      <Typography
                        variant="p"
                        text={`Double Check`}
                        color="#FC4447"
                        size="14px"
                        weight="400"
                        margin="0 0 0 5px"
                      />
                    ) : order?.custom?.custom_group?.custom_tax?.id &&
                      order?.custom?.custom_group?.custom_doc
                        ?.client_current_custom_doc?.status !== "accepted" ? (
                      <Typography
                        variant="p"
                        text={`Tax occurred`}
                        color="#FC4447"
                        size="14px"
                        weight="400"
                        margin="0 0 0 5px"
                      />
                    ) : order?.custom?.custom_group?.declaration_group?.id &&
                      order?.custom?.custom_group?.custom_doc
                        ?.client_current_custom_doc?.status !== "accepted" ? (
                      <Typography
                        variant="p"
                        text={`Need declaration`}
                        color="#FC4447"
                        size="14px"
                        weight="400"
                        margin="0 0 0 5px"
                      />
                    ) : order?.custom?.custom_group?.custom_status?.status ===
                      "in" ? (
                      <Typography
                        variant="p"
                        text={`In customs`}
                        color="#FC4447"
                        size="14px"
                        weight="400"
                        margin="0 0 0 5px"
                      />
                    ) : (
                      <Typography
                        variant="p"
                        text={`Not Ready`}
                        color="#FC4447"
                        size="14px"
                        weight="400"
                        margin="0 0 0 5px"
                      />
                    )}
                  </Flex>
                ) : (
                  <>
                    <EstimateIcon />
                    <Typography
                      variant="p"
                      text={`Estimated`}
                      color="#5B6D7F"
                      size="14px"
                      weight="400"
                      margin="0 0 0 5px"
                    />
                    <Typography
                      variant="p"
                      text={
                        order?.estimated_date_to
                          ? dayjs(order?.estimated_date_to).format("DD.MM.YYYY")
                          : ""
                      }
                      color="#262626"
                      size="14px"
                      weight="400"
                      margin="0 0 0 5px"
                    />
                  </>
                )}
              </Flex>
            </Flex>
            {!!order.service && (
              <Flex alignItems="center" style={{ paddingTop: 10 }}>
                <img src={order?.service?.smart_service.image} width="25" />
                <Flex alignItems="center">
                  <Typography
                    variant="p"
                    text={
                      order?.service?.smart_service?.current_smart_service
                        ?.name || ""
                    }
                    color="#5B6D7F"
                    size="14px"
                    weight="400"
                    margin="0 0 0 5px"
                  />
                  <Tag
                    text={order?.service?.status || ""}
                    background="#EDFBFE"
                    color="#47BFE1"
                    margin="0 0 0 8px"
                  />
                </Flex>
              </Flex>
            )}
          </div>
        ),
        pickup_point: (
          <Flex>
            <Typography
              variant="p"
              text={order?.pickup_point?.name}
              size={"14px"}
            />
            {order?.delivery && order?.delivery?.type === "home" ? (
              <Flex style={{ marginTop: 10 }}>
                <DeliveryIcon />
                <Typography
                  variant="p"
                  text={"Home delivery"}
                  size={"14px"}
                  padding={"0 4px 0 5px"}
                />
                <OnexTooltip
                  direction={"top"}
                  content={`${order?.delivery?.delivery_home?.delivery_date}`}
                  content2={`${order?.delivery?.delivery_home?.courier_name}, ${order?.delivery?.delivery_home?.courier_phone}`}
                >
                  <InfoIcon />
                </OnexTooltip>
              </Flex>
            ) : (
              ""
            )}
            <Flex style={{ margin: "0 0 0 auto" }}>
              {order?.warehouse_photos?.length ? (
                <WhIcon
                  onClick={() =>
                    setPhotoOrder(order.warehouse_photos[0]?.photo)
                  }
                />
              ) : null}
            </Flex>
          </Flex>
        ),
      };
    });
  }
  return (
    <StyledOrdersTable>
      <ImageModal status={photoOrder} setStatus={setPhotoOrder} />{" "}
      {contextHolder}
      {!isLoading ? (
        <div>
          <Table columns={columns} dataSource={data} pagination={false} />
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Flex justifyContent={"space-between"}>
              <Typography
                variant={"p"}
                text={`Cost: ${meta?.options?.total_cost}  ₾ | `}
                padding={"0 5px 0 5px"}
              />
              <Typography
                variant={"p"}
                text={` Add.Cost: ${meta?.options?.total_additional_cost}  ₾ | `}
                padding={"0 5px 0 5px"}
              />
              <Typography
                variant={"p"}
                text={` V Weight: ${meta?.options?.total_v_weight} |`}
                padding={"0 5px 0 5px"}
              />
              <Typography
                variant={"p"}
                text={` Weight: ${meta?.options?.total_weight}`}
                padding={"0 5px 0 5px"}
              />
            </Flex>
            <div className={"new-pagination"} style={{ width: "auto" }}>
              <Pagination
                total={meta?.total || 1}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                onChange={(page) => setFilters({ ...filters, page })}
                defaultPageSize={meta?.per_page || 15}
                defaultCurrent={meta?.current_page || 1}
              />
            </div>
          </Flex>
        </div>
      ) : (
        <Loader />
      )}
      <Tooltip title="sdasdsadasdsad" trigger="hover" zIndex={9999}>
        <div className="download-excel">
          <ButtonSecondary
            text="Excel"
            click={() => handleDownloadExcel()}
            icon={<DownloadIcon />}
          />
        </div>
      </Tooltip>
    </StyledOrdersTable>
  );
}
